var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{},[_c('CCol',{attrs:{"col":""}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Create Article")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Section","vid":"section_id","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"section_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Section "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"id":"section_id","options":_vm.sections,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.section_id),callback:function ($$v) {_vm.$set(_vm.formData, "section_id", $$v)},expression:"formData.section_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Select Category","vid":"category_id","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"options":_vm.articleCategoryList,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Select Company","vid":"company_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Company "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"id":"company_id","options":_vm.companyList,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.company_id),callback:function ($$v) {_vm.$set(_vm.formData, "company_id", $$v)},expression:"formData.company_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Title","vid":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"title","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Expire Date","vid":"expire_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Expire Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"date","id":"expire_date","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.expire_date),callback:function ($$v) {_vm.$set(_vm.formData, "expire_date", $$v)},expression:"formData.expire_date"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Total Views","vid":"views","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Total Views ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"disabled":"","type":"text","id":"views","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.views),callback:function ($$v) {_vm.$set(_vm.formData, "views", $$v)},expression:"formData.views"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Apply Link","vid":"sort","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sort "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"sort","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.sort),callback:function ($$v) {_vm.$set(_vm.formData, "sort", $$v)},expression:"formData.sort"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Apply Link","vid":"apply_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Apply Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"apply_link","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.apply_link),callback:function ($$v) {_vm.$set(_vm.formData, "apply_link", $$v)},expression:"formData.apply_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","vid":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('VueEditor',{attrs:{"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2 btn-sm",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save")]),_c('b-button',{staticClass:"mr-2 btn-sm",attrs:{"type":"button","variant":"danger"},on:{"click":function (){_vm.$router.push('/articles')}}},[_vm._v("Cancel")])],1)])])]}}])})],1)],1),(_vm.id)?_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveDetail)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Name","vid":"name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"detailForm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"name","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.detailForm.name),callback:function ($$v) {_vm.$set(_vm.detailForm, "name", $$v)},expression:"detailForm.name"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Logo","vid":"file","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"detailForm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" File/Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"file","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.setFile},model:{value:(_vm.detailForm.file),callback:function ($$v) {_vm.$set(_vm.detailForm, "file", $$v)},expression:"detailForm.file"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-2"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"37px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.detailForm.id ? 'Update' : 'Save'))])],1)])])]}}],null,false,3476196998)})],1):_vm._e(),(_vm.id)?_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-sm table-bordered",staticStyle:{"width":"100% !important"}},[_c('tr',[_c('th',[_vm._v("Sl No")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("File")]),_c('th',{staticClass:"text-center"},[_vm._v("Action")])]),_vm._l((_vm.formData.attachments),function(file,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(_vm.getFileExtension(file.path)))]),_c('td',[_vm._v(_vm._s(file.name))]),_c('td',[_c('a',{staticClass:"btn btn-info btn-sm text-light",attrs:{"href":_vm._f("filePath")(file.path),"download":""}},[_vm._v("File")])]),_c('td',{staticClass:"text-center"},[_c('b-button',{staticClass:"mr-2 btn-sm",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.setEdit(file)}}},[_vm._v("Edit")]),_c('b-button',{staticClass:"mr-2  btn-sm",attrs:{"type":"button","variant":"danger"},on:{"click":function($event){return _vm.removeDetail(file.id)}}},[_vm._v("x")])],1)])})],2)]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }