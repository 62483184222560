<template>
    <CRow class="">
      <CCol col>
        <CCard>
            <CCardHeader>
                <div class="row">
                    <div class="col-md-6">
                        <strong>Create Article</strong>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <div class="row">
                    <b-col lg="12" sm="12">
                      <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                    <div class="row">
                                        <div class="col-md-4">
                                            <ValidationProvider name="Section" vid="section_id" rules="required|min_value:1">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="section_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Section  <span class="text-danger">*</span>
                                                </template>
                                                    <Select2
                                                     id="section_id"
                                                      v-model="formData.section_id"
                                                      :options="sections"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                    />
                                                    <div class="text-danger">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Select Category" vid="category_id" rules="required|min_value:1">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Select Category  <span class="text-danger">*</span>
                                                </template>
                                                    <Select2
                                                      v-model="formData.category_id"
                                                      :options="articleCategoryList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                       />
                                                        <div class="text-danger">
                                                          {{ errors[0] }}
                                                       </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Select Company" vid="company_id" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Select Company  <span class="text-danger">*</span>
                                                </template>
                                                <Select2
                                                    id="company_id"
                                                    v-model="formData.company_id"
                                                    :options="companyList"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                />
                                                <div class="text-danger">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Title" vid="title" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    Title <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="text"
                                                    id="title"
                                                    v-model="formData.title"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Expire Date" vid="expire_date" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    Expire Date <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="date"
                                                    id="expire_date"
                                                    v-model="formData.expire_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Total Views" vid="views" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    Total Views
                                                </template>
                                                <b-form-input
                                                    disabled
                                                    type="text"
                                                    id="views"
                                                    v-model="formData.views"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4">
                                            <ValidationProvider name="Apply Link" vid="sort" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                Sort <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="sort"
                                                    rows="6"
                                                    v-model="formData.sort"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                        <div class="col-md-4">
                                            <ValidationProvider name="Apply Link" vid="apply_link" rules="">
                                                <!-- <select2 :data="articleCategoryList"
                                                    :value="value"
                                                    @update="update($event)">
                                                </select2> -->
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    Apply Link
                                                </template>
                                                <b-form-input
                                                    id="apply_link"
                                                    rows="6"
                                                    v-model="formData.apply_link"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <ValidationProvider name="Description" vid="description" rules="">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="formData"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    Description <span class="text-danger">*</span>
                                                </template>
                                                <VueEditor 
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="formData.description"
                                                >
                                                </VueEditor>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12 text-right">
                                            <b-button type="submit" variant="primary" class="mr-2 btn-sm">Save</b-button>
                                            <b-button type="button" @click="()=>{$router.push('/articles')}" variant="danger" class="mr-2 btn-sm">Cancel</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </b-col>
                    <div v-if="id" class="col-md-12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(saveDetail)" @reset.prevent="reset" >
                                <div class="row">
                                    <div class="col-md-4">
                                        <ValidationProvider name="Name" vid="name" rules="">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="detailForm"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                Name <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                type="text"
                                                id="name"
                                                v-model="detailForm.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-4">
                                        <ValidationProvider name="Logo" vid="file" rules="">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12"
                                            label-for="detailForm"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                File/Image <span class="text-danger">*</span>
                                            </template>
                                            <b-form-file
                                                type="text"
                                                id="file"
                                                @change="setFile"
                                                v-model="detailForm.file"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-2">
                                        <b-button type="submit" variant="primary" style="margin-top:37px" class="mr-2">{{ detailForm.id ? 'Update' : 'Save' }}</b-button>
                                    </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </div>
                    <div  v-if="id" class="col-md-12">
                        <table class="table table-sm table-bordered" style="width: 100% !important">
                            <tr>
                                <th>Sl No</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>File</th>
                                <th class="text-center">Action</th>
                            </tr>
                            <tr v-for="(file, index) in formData.attachments" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ getFileExtension(file.path) }}</td>
                                <td>{{ file.name }}</td>
                                <td><a  :href="file.path | filePath" class="btn btn-info btn-sm text-light" download>File</a></td>
                                <td class='text-center'>
                                    <b-button @click="setEdit(file)" type="button" variant="primary" class="mr-2 btn-sm">Edit</b-button>
                                    <b-button @click="removeDetail(file.id)" type="button" variant="danger" class="mr-2  btn-sm">x</b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
import { VueEditor } from "vue2-editor";
import Select2 from 'v-select2-component';
import { ARTICLE } from '../../Utils/Constant';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor,
    Select2

  },
  data () {
    return {
      saveBtnName: "Update",
      formData: {
        'sort': 1,
        'section_id': null,
        'category_id': null,
        'logo_file': [],
        'company_id': '',
        'title': '',
        'ads_token': '',
        'google_analytic_token': '',
        'description': '',
        'maintanance_mode': '',
        'ads_id': '',
        'apply_link': '',
      },
      detailForm: {
        'id': '',
        'file_type': 1,
        'file': [],
        'title': ''
      }
    }
  },
  watch: {
        'formData.section_id' : function (val) {
            this.getArticleCategoryList(val)
        }
  },
  computed: {
    sections () {
        const data = this.$store.state.commonObj.sectionList.filter(item => item.type_id == ARTICLE)
        data.unshift({ 'id': 0, 'text': 'Select', 'type_id': '1'})
        return data
    },
    id() {
        return this.$route.query.id
    },
	loading () {
		return this.$store.state.static.loading
	},
    companyList () {
        return this.$store.state.commonObj.companyList
    }
  },
  methods: {
    getArticleCategoryList (sectionId) {
        const data = this.$store.state.commonObj.articleCategoryList.filter(item => item.section_id == sectionId)
        data.unshift({ 'id': 0, 'text': 'Select'})
        this.articleCategoryList = data
    },
    myChangeEvent(val){
            console.log(val);
    },
    mySelectEvent({id, text}){
        console.log({id, text})
    },
    setFile (event) {
        this.formData.logo_file = event.target.files[0]
    },
    setEdit (item) {
        this.detailForm = JSON.parse(JSON.stringify(item)) 
    },
    getData () {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/articles/show', {id: this.id}).then(response => {
            if (response.success) {
                this.formData = {...response.data}
                this.formData.maintanance_mode = response.data.maintanance_mode == '1' ? true : false
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
        this.$store.dispatch('mutedLoad', { loading: true})
    },
    async register () {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null

        let formData = new FormData()
        Object.keys(this.formData).map(key => {
            formData.append(key, this.formData[key] ? this.formData[key] : '')
        });
        if (this.id) {
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/articles/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/articles/store', formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false })

        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})

            setTimeout(()=> {
                this.$router.push('/articles/create?id='+result.data.id)
            }, 1000)
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    async saveDetail () {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null

        let formData = new FormData()
        Object.keys(this.detailForm).map(key => {
            formData.append(key, this.detailForm[key])
        });
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/articles/detail/store'}/${this.id}`, formData)
            this.$store.dispatch('mutedLoad', { loading: false })
            this.getData()
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    async removeDetail (id) {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.deleteData(baseUrl, `${'api/articles/detail/delete'}/${id}`)
        this.$store.dispatch('mutedLoad', { loading: false })
        
        this.getData()
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    getFileExtension(filePath) {
        return filePath.split('.').pop();
    }
  },
  mounted () {    
    this.$store.dispatch('mutedLoad', { loading: false })
    if (this.id) {
        this.getData()
    }
  }
}
</script>